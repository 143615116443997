export function getUserShortenedName(firstName?: string | null, lastName?: string | null) {
  if (firstName && lastName) {
    return `${firstName} ${lastName[0]}.`;
  }

  if (firstName) {
    return firstName;
  }

  if (lastName) {
    return lastName;
  }

  return '';
}

export function getUserInitials(firstName?: string | null, lastName?: string | null) {
  if (firstName && lastName) {
    return `${firstName[0].toUpperCase()}${lastName[0].toUpperCase()}`;
  }

  if (firstName) {
    return firstName[0].toUpperCase();
  }

  if (lastName) {
    return lastName[0].toUpperCase();
  }

  return '';
}

export function getUserFullName(firstName?: string | null, lastName?: string | null) {
  if (firstName && lastName) {
    return `${firstName} ${lastName}`;
  }

  if (firstName) {
    return firstName;
  }

  if (lastName) {
    return lastName;
  }

  return '';
}

export function isValidUrl(urlString?: string) {
  if (!urlString) {
    return false;
  }

  try {
    return Boolean(new URL(urlString));
  } catch (e) {
    return false;
  }
}
